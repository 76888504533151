import { initializeApp, getApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";

// Firebase configurations
const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	cloudFunctionsUrl: `https://europe-west6-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/`,
};
// initialize firebase
const region = "europe-west6";
try {
	const _app = initializeApp(firebaseConfig);
	getAuth(_app);
	getFirestore(_app);
	getStorage(_app);
	getFunctions(_app, region);
} catch (error) {
	console.error("Firebase error", error);
}
export const inProductiveEnvironment =
	firebaseConfig.projectId === "nimagna-ag";
const origin = window.location.origin;
export const isLocal =
	origin.indexOf("localhost") >= 0 || origin.indexOf("127.0.0.1") >= 0;

// Use local emulator during development. If set to false, the test environment will be used.
export const useEmulatorLocally = false;
var cloudFunctions = firebaseConfig.cloudFunctionsUrl;

if (inProductiveEnvironment) {
	// productive
	console.log("Productive environment!");
} else {
	// test
	console.log(`Test environment using ${firebaseConfig.projectId}`);
	if (isLocal && useEmulatorLocally) {
		// emulator
		console.log("Emulator mode!");
		try {
			const _app = getApp();
			connectFirestoreEmulator(getFirestore(), "127.0.0.1", 8080);
			connectAuthEmulator(getAuth(), "http://127.0.0.1:9099");
			connectStorageEmulator(getStorage(), "127.0.0.1", 9199);
			connectFunctionsEmulator(getFunctions(_app, region), "127.0.0.1", 5001);
			cloudFunctions = "http://127.0.0.1:5001/nimagnatest/europe-west6/";
		} catch (error) {
			console.error("Error connecting to emulator", error);
		}
	}
}

// export auth, firestore, and storage
const app = getApp();
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app, region);
export const storageBucketUrl = `gs://${firebaseConfig.storageBucket}/releases/`;
export const cloudFunctionsUrl = cloudFunctions;
