import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useReleaseData } from "../context/DataContext.js";
import { useNavigate } from "react-router";

/**
 * Displays an overview of the releases, filtered by year and including buttons to change the year.
 */

export function Releases({ children }) {
	const { releases, refreshReleases } = useReleaseData();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const showDownloads = searchParams.get("downloads")
		? searchParams.get("downloads")
		: false;

	var now = new Date();
	const [year, setYear] = useState(now.getFullYear());
	const [years, setYears] = useState([]);

	function download(url, image, text) {
		if (url) {
			return (
				<Link to={url}>
					<img src={image} width="30" alt={text} />
					<br />
					<span className="is-size-7">{text}</span>
				</Link>
			);
		}
		return null;
	}

	// filter by year
	const filteredReleases = releases.filter((release) => {
		var releaseYear = release.date.toDate().getFullYear();
		return releaseYear === year;
	});
	const releaseItems = filteredReleases.map((release) => {
		var latest = release === releases[0];
		var link = "/release/" + release.version;
		var date = release.date.toDate();
		var featureRelease = release.versionArray[2] === "0";
		var tdStyle = "has-text-centered ";
		tdStyle =
			tdStyle +
			((featureRelease || latest) && release.active === true
				? "has-text-weight-bold has-background-info-soft has-text-bold"
				: "has-text-weight-light");
		tdStyle = (latest ? " has-text-primary " : "") + tdStyle;
		var trStyle = latest ? "is-danger is-clickable" : "is-clickable";
		var firstStyle =
			(release.active === false ? "is-warning " : "") + tdStyle + " vertical";
		var firstText =
			release.active === false
				? "Internal "
				: latest
				? "Latest"
				: featureRelease
				? "Feature"
				: "";
		return (
			<tr
				key={release.id}
				className={trStyle}
				onClick={() => {
					navigate(link);
				}}
			>
				<td className={firstStyle}>{firstText}</td>
				<td className={tdStyle}>
					<Link to={link}>{release.version}</Link>
				</td>
				<td className={tdStyle}>
					<Link to={link}>Release notes</Link>
				</td>
				{showDownloads ? (
					<>
						<td className={tdStyle}>
							{download(
								release.url_windows,
								"images/windows-logo.svg",
								"Windows"
							)}
						</td>
						<td className={tdStyle}>
							{download(
								release.url_mac_arm,
								"images/macOS-logo.svg",
								"macOS arm64"
							)}
						</td>
						<td className={tdStyle}>
							{download(
								release.url_mac_intel,
								"images/macOS-logo.svg",
								"macOS x86"
							)}
						</td>
					</>
				) : (
					<></>
				)}
				<td className={tdStyle}>{date.toLocaleDateString()}</td>
			</tr>
		);
	});
	const yearItems = years.map((buttonYear) => {
		var style = "button";
		if (buttonYear === year) {
			style += " is-active is-primary";
		}
		return (
			<button
				className={style}
				onClick={() => {
					setYear(buttonYear);
				}}
				m="1"
				key={buttonYear}
			>
				{buttonYear}
			</button>
		);
	});

	useEffect(() => {
		refreshReleases();
	});
	useEffect(() => {
		// collect all years
		const years_set = new Set();
		releases.forEach((release) => {
			var releaseYear = release.date.toDate().getFullYear();
			years_set.add(releaseYear);
		});
		setYears(Array.from(years_set));
	}, [releases]);

	return (
		<>
			<div className="columns mt-5">
				<div className="column is-one-fifth"></div>
				<div className="column is-three-fifths is-white ">
					<h1 className="title is-1 has-text-centered">Nimagna Releases</h1>
					<div className="block has-text-centered">
						Overview of all public Nimagna releases.
					</div>
					<div className="buttons is-centered has-addons">{yearItems}</div>
					<table className="table is-hoverable">
						<thead>
							<tr>
								<th colSpan="2">Release</th>
								<th>Release Notes</th>
								{showDownloads ? (
									<>
										<th colSpan="3">Downloads</th>
									</>
								) : (
									<></>
								)}
								<th>Release Date</th>
							</tr>
						</thead>
						<tbody>{releaseItems}</tbody>
					</table>
				</div>
				<div className="column is-one-fifth"></div>
			</div>
		</>
	);
}
