import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import "bulma/css/bulma.css";
import "./release-app.css";
import "./App.css";
import { Navigation } from "./components/Navigation";
import { Releases } from "./components/Releases";
import { Release } from "./components/Release";
import { Year } from "./components/Helpers";
import { DataContextProvider } from "./context/DataContext";

/**
 * The main App creates the routing to show different elements based on the URL.
 * The main layout is defined in Layout() at the bottom.
 */

const router = createBrowserRouter([
	{
		// the general page layout
		element: <Layout />,
		children: [
			{ path: "/", element: <Releases /> },
			{ path: "releases", element: <Releases /> },
			{ path: "release/:version", element: <Release /> },
		],
	},
]);
export default function App() {
	return (
		<>
			<RouterProvider router={router} />
		</>
	);
}
function Layout() {
	return (
		<>
			<DataContextProvider>
				<Navigation />
				<div className="block" m="5">
					<Outlet />
				</div>
				<footer className="footer has-text-centered">
					This page provides information about public releases of Nimagna.
					<br />
					Visit <a href="https://www.nimagna.com">nimagna.com</a> for more
					information about Nimagna.
					<br />
					&copy; <Year /> by Nimagna AG. All rights reserved.
				</footer>
			</DataContextProvider>
		</>
	);
}
