import { useReleaseData } from "../context/DataContext.js";
import { useState } from "react";
import { Link } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiWeatherSunny, mdiMoonWaningCrescent } from "@mdi/js";

export function Navigation({ children }) {
	const { theme, toggleTheme } = useReleaseData();
	const [isActive, setisActive] = useState(false);

	return (
		<>
			<nav
				className="navbar is-primary"
				role="navigation"
				aria-label="main navigation"
			>
				<div className="navbar-brand has-background-primary-30">
					<Link to="/releases" className="navbar-item">
						<img
							alt="Nimagna"
							height="50"
							src="/images/nimagna_icon_new.svg"
							width="50"
						/>
					</Link>
					<button
						className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
						data-target="navbarBasicExample"
						onClick={() => {
							setisActive(!isActive);
						}}
					>
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
					</button>
				</div>
				<div
					className={`navbar-menu ${isActive ? "is-active" : ""}`}
					id="navbarBasicExample"
				>
					<div className="navbar-start">
						<Link className="navbar-item" to="/releases">
							Release Overview
						</Link>
						<button
							className="navbar-item is-hidden-tablet"
							onClick={toggleTheme}
						>
							{theme === "light" ? (
								<>
									<Icon path={mdiWeatherSunny} size={1} /> Light theme
								</>
							) : (
								<>
									<Icon path={mdiMoonWaningCrescent} size={1} /> Dark theme
								</>
							)}
						</button>
					</div>
					<div className="navbar-end">
						<button
							className="button navbar-item is-primary m-1 is-hidden-mobile"
							onClick={toggleTheme}
						>
							{theme === "light" ? (
								<Icon path={mdiWeatherSunny} size={1} />
							) : (
								<Icon path={mdiMoonWaningCrescent} size={1} />
							)}
						</button>
					</div>
				</div>
			</nav>
		</>
	);
}

export function Loader() {
	return (
		<>
			<img src="/images/loader-botao.gif" width="20" alt="Loading..." />
		</>
	);
}
